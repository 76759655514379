<template>
  <div>
    <template v-if="reviews.length">
      <b-card v-if="showSummary" class="py-2">
        <rating-summary
          :rating="rating"
          :average-rating="averageRating"
          :max-rating="maxRating"
        />
      </b-card>

      <b-row>
        <b-col
          v-for="(review, reviewIndex) in reviews"
          :key="`review-preview-${review.uuid}`"
          cols="12"
        >
          <b-card
            :class="{
              'border-bottom mb-4 p-0': reviewIndex < reviews.length - 1,
            }"
          >
            <review-card
              :review="review"
              :max-rating="maxRating"
              :hide-accommodation-name="hideAccommodationName"
            />
          </b-card>
        </b-col>
      </b-row>
    </template>

    <b-card v-else>
      <div
        class="d-flex flex-column justify-content-center align-items-center py-5"
      >
        <feather-icon icon="AwardIcon" size="25" class="mr-2 mb-3" />
        <h5>
          {{ $t("pages.reviews.noReviewsYet") }}
        </h5>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import ReviewCard from "@/views/reviews/list/components/ReviewCard.vue";
import RatingSummary from "@/views/reviews/list/components/RatingSummary.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    RatingSummary,
    ReviewCard,
  },
  props: {
    rating: {
      type: Object,
      default: null,
    },
    averageRating: {
      type: Number,
      default: null,
    },
    maxRating: {
      type: Number,
      default: null,
    },
    reviews: {
      type: Array,
      default: () => [],
    },
    hideSummary: {
      type: Boolean,
      default: false,
    },
    hideAccommodationName: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showSummary() {
      return !this.hideSummary;
    },
  },
};
</script>
