<template>
  <div id="accommodation-view">
    <vue-horizontal class="mb-3 border-bottom">
      <template v-slot:btn-prev>
        <feather-icon
          size="32"
          height="100"
          class="btn-left"
          icon="ChevronLeftIcon"
        />
      </template>
      <template v-slot:btn-next>
        <feather-icon
          size="32"
          height="100"
          class="btn-right"
          icon="ChevronRightIcon"
        />
      </template>
      <div @click="tabIndex = 0" :class="getTabClass(tabIndex, 0)">
        <feather-icon class="mr-1" icon="HomeIcon" />
        <span>
          {{ $t("Details") }}
        </span>
      </div>
      <div @click="tabIndex = 1" :class="getTabClass(tabIndex, 1)">
        <feather-icon class="m-1" icon="ListIcon" />
        <span>
          {{ $t("Bookings") }}
        </span>
      </div>
      <div @click="tabIndex = 2" :class="getTabClass(tabIndex, 2)">
        <feather-icon class="m-1" icon="CalendarIcon" />
        <span>
          {{ $t("Calendar") }}
        </span>
      </div>
      <div @click="tabIndex = 3" :class="getTabClass(tabIndex, 3)">
        <feather-icon class="m-1" icon="AwardIcon" />
        <span>
          {{ $t("Reviews") }}
        </span>
      </div>
    </vue-horizontal>

    <b-tabs
      v-if="accommodation"
      no-fade
      v-model="tabIndex"
      class="accommodation-view"
      nav-wrapper-class="d-none"
    >
      <b-tab active>
        <summary-card />
        <location-card v-if="location" />
        <multimedia-card v-if="pictures.length" />
        <accommodation-services-card />
        <distribution-card v-if="bedrooms.length || bathrooms.length" />
        <amenities-card v-if="amenities.length" />
      </b-tab>

      <b-tab>
        <bookings-card />
      </b-tab>

      <b-tab>
        <calendar-card />
      </b-tab>

      <b-tab>
        <reviews-card />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import SummaryCard from "@/views/accommodations/accommodation/components/SummaryCard.vue";
import LocationCard from "@/views/accommodations/accommodation/components/LocationCard.vue";
import MultimediaCard from "@/views/accommodations/accommodation/components/MultimediaCard.vue";
import AccommodationServicesCard from "@/views/accommodations/accommodation/components/AccommodationServicesCard.vue";
import DistributionCard from "@/views/accommodations/accommodation/components/DistributionCard.vue";
import AmenitiesCard from "@/views/accommodations/accommodation/components/AmenitiesCard.vue";
import BookingsCard from "@/views/accommodations/accommodation/components/BookingsCard.vue";
import CalendarCard from "@/views/accommodations/accommodation/components/CalendarCard.vue";
import ReviewsCard from "@/views/accommodations/accommodation/components/ReviewsCard.vue";
import VueHorizontal from "vue-horizontal";

export default {
  components: {
    BTabs,
    BTab,
    SummaryCard,
    LocationCard,
    MultimediaCard,
    DistributionCard,
    AmenitiesCard,
    AccommodationServicesCard,
    BookingsCard,
    CalendarCard,
    ReviewsCard,
    VueHorizontal,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    location() {
      return this.$store.getters["accommodation/location"];
    },
    pictures() {
      return this.$store.getters["accommodation/pictures"];
    },
    bedrooms() {
      return this.$store.getters["accommodation/bedrooms"];
    },
    bathrooms() {
      return this.$store.getters["accommodation/bathrooms"];
    },
    amenities() {
      return this.$store.getters["accommodation/amenities"];
    },
    loadingOwner() {
      return this.$store.getters["owner/loading"];
    },
    owner() {
      return this.$store.getters["owner/owner"];
    },
    currentAccommodations() {
      return this.$store.getters["owner/currentAccommodations"];
    },
    services() {
      return this.$store.getters["accommodation/services"];
    },
  },
  watch: {
    accommodation(accommodation) {
      this.setPageTitle(accommodation);
    },
  },
  created() {
    this.fetchAccommodation();
    if (!this.loadingOwner && !this.owner) this.fetchOwner();
  },
  mounted() {
    this.setPageTitle(this.accommodation);
  },
  destroyed() {
    this.setPageTitle(null);
  },
  methods: {
    getTabClass(selectedIndex, index) {
      return ` font-weight-bold pb-3 cursor-pointer  px-3 ${
        selectedIndex === index ? "btn-active" : ""
      }`;
    },
    fetchAccommodation() {
      const { slug } = this.$route.params;

      if (slug === this.accommodation?.slug) {
        this.fetchAccommodationDetails();
        return;
      }
      this.$store.dispatch("app/setLoading", true);
      this.$store.dispatch("accommodation/reset");
      this.$store
        .dispatch("accommodation/fetchAccommodationBySlug", slug)
        .then(() => this.fetchAccommodationDetails())
        .catch(() =>
          this.$router.push({ name: "foravila-accommodations-list" })
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    fetchAccommodationDetails() {
      this.$store
        .dispatch("accommodation/fetchPictures", this.accommodation.uuid)
        .catch(() => {});
      this.$store
        .dispatch("accommodation/fetchBedrooms", this.accommodation.uuid)
        .catch(() => {});
      this.$store
        .dispatch("accommodation/fetchBathrooms", this.accommodation.uuid)
        .catch(() => {});
      this.$store
        .dispatch("accommodation/fetchServices", this.accommodation.uuid)
        .catch(() => {});
      this.$store
        .dispatch("accommodation/fetchAmenities", this.accommodation.uuid)
        .catch(() => {});
      this.$store
        .dispatch("accommodation/fetchAvailabilities", this.accommodation.uuid)
        .catch(() => {});
      this.$store
        .dispatch("accommodation/fetchBookings", this.accommodation.uuid)
        .catch(() => {});
      this.$store
        .dispatch("accommodation/fetchReviews", this.accommodation.uuid)
        .catch(() => {});
    },
    fetchOwner() {
      const userData = JSON.parse(
        localStorage.getItem("foravilaOwnersUserData")
      );
      const ownerUuid = userData?.owner?.uuid || null;
      this.$store.dispatch("owner/fetchOwner", ownerUuid).catch(() => {
        // TODO: pensar que ha de passar si es fetchOwner ens dona error
      });
    },
    setPageTitle(accommodation) {
      this.$store.dispatch("app/setPageTitle", accommodation?.name || null);

      const cityText = accommodation?.location?.city || null;
      if (!cityText) {
        this.$store.dispatch("app/setPageSubtitle", null);
        return;
      }

      const foundIndex = cityText?.indexOf("(") || null;
      if (foundIndex === -1) {
        this.$store.dispatch("app/setPageSubtitle", cityText);
        return;
      }

      const subtitle = cityText ? cityText.substring(0, foundIndex - 1) : null;
      this.$store.dispatch("app/setPageSubtitle", subtitle);
    },
  },
};
</script>

<style lang="scss">
#accommodation-view {
  // Posam aquest min height perquè s'spinner mentre carrega informació quedi visible
  min-height: 200px;

  .btn-left,
  .btn-right {
    padding: 6px;
    height: 100%;
  }
  .btn-left {
    background: linear-gradient(to left, #ffffff00 0, #fff 50%, #fff);
  }
  .btn-right {
    background: linear-gradient(to right, #ffffff00 0, #fff 50%, #fff);
  }
  .btn-active {
    color: #ffcd00;
    border-bottom: 2px solid;
  }
}
</style>
