<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <b-card class="calendar-card">
      <b-row v-if="!loading">
        <b-col>
          <calendar
            :bookings="bookings"
            :availabilities="availabilities"
            :owner-rates="ownerRates"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BRow, BCard, BCol } from "bootstrap-vue";
import Calendar from "@/views/bookings/calendar/components/Calendar.vue";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    Calendar,
  },
  computed: {
    loading() {
      return this.$store.getters["accommodation/loadingBookings"];
    },
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    ownerRates() {
      if (!this.accommodation?.uuid) return [];
      const accommodationsOwnerRates =
        this.$store.getters["owner/accommodationRates"];
      let foundRates = [];
      Object.entries(accommodationsOwnerRates).forEach((entry) => {
        const [accommodationUuid, accommodationRates] = entry;
        if (accommodationUuid === this.accommodation?.uuid) {
          foundRates = foundRates.concat(accommodationRates);
        }
      });
      return foundRates;
    },
    bookings() {
      return this.$store.getters["accommodation/bookings"];
    },
    availabilities() {
      return this.$store.getters["accommodation/availabilities"];
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-card {
  min-height: 200px;
}
</style>
