<template>
  <div>
    <div v-if="client" class="border-bottom py-3 d-flex align-items-center">
      <h5 class="mb-0">
        {{ client }}
      </h5>
    </div>
    <div
      v-if="accommodationName"
      class="d-flex justify-content-between border-bottom py-3"
    >
      <div>
        {{ $t("Accommodation") }}
      </div>
      <div>
        <div class="mb-0 text-right">
          {{ accommodationName }}
        </div>
      </div>
    </div>
    <div>
      <div
        v-if="checkinText"
        class="d-flex justify-content-between border-bottom py-3"
      >
        <div>{{ $t("Check-in") }}</div>
        <div class="text-right">
          {{ checkinText }}
        </div>
      </div>
      <div
        v-if="checkoutText"
        class="d-flex justify-content-between border-bottom py-3"
      >
        <div>{{ $t("Check out") }}</div>
        <div class="text-right">
          {{ checkoutText }}
        </div>
      </div>
      <div
        v-if="guests"
        class="d-flex justify-content-between border-bottom py-3"
      >
        <div class="pr-3">
          {{ $t("Guests") }}
        </div>
        <div class="text-right">
          {{ guests }}
        </div>
      </div>
      <div v-if="ownerPrice" class="d-flex justify-content-between py-3">
        <div>{{ $t("pages.booking.yourPrice") }}</div>
        <div>{{ ownerPrice }}</div>
      </div>
    </div>
    <b-button
      variant="primary"
      block
      class="shadow-lg mt-3"
      @click="onSeeDetails"
    >
      {{ $t("See details") }}
    </b-button>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import { formatCurrency, formatDateObjectToDate } from "@formatters";
import { getGuestsText } from "@methods";

export default {
  components: {
    BButton,
  },
  props: {
    booking: {
      type: Object,
      required: true,
    },
  },
  computed: {
    client() {
      return this.booking?.client || null;
    },
    clientInitials() {
      if (!this.client) return null;
      const fullName = this.client.split(" ");
      const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      return initials.toUpperCase();
    },
    accommodationName() {
      return this.booking?.accommodation;
    },
    checkin() {
      return this.formatBookingDate(this.booking?.startDate) || null;
    },
    checkinTime() {
      return this.booking?.accommodationArrivalTime || null;
    },
    checkinText() {
      const checkinText = [this.checkin];
      if (this.checkinTime) checkinText.push(this.checkinTime);
      return checkinText.length ? `${checkinText.join(" ")}` : null;
    },
    checkout() {
      return this.formatBookingDate(this.booking?.endDate) || null;
    },
    checkoutTime() {
      return this.booking?.accommodationDepartureTime || null;
    },
    checkoutText() {
      const checkoutText = [this.checkout];
      if (this.checkoutTime) checkoutText.push(this.checkoutTime);
      return checkoutText.length ? `${checkoutText.join(" ")}` : null;
    },
    guests() {
      return getGuestsText(
        {
          adults: this.booking?.adults || null,
          children: this.booking?.children || null,
          babies: this.booking?.babies || null,
        },
        this.$i18n.locale
      );
    },
    ownerPrice() {
      return formatCurrency(this.booking?.ownerPrice);
    },
  },
  methods: {
    formatBookingDate(date) {
      if (!date) return null;
      const formatting = { day: "numeric", weekday: "short", month: "short" };
      return formatDateObjectToDate(date, this.$i18n.locale, formatting);
    },
    onSeeDetails() {
      if (!this.booking?.localizator) return;

      this.$router.push({
        name: "foravila-booking-view",
        params: { localizator: this.booking.localizator },
      });
    },
  },
};
</script>
