<template>
  <div class="d-flex align-items-center">
    <feather-icon
      v-if="isAmenityAvailable(code)"
      icon="CheckCircleIcon"
      class="mr-2 text-primary"
    />
    <feather-icon v-else icon="XCircleIcon" class="mr-2 text-danger" />
    <span>{{ getAmenityName(code) }}</span>
  </div>
</template>

<script>
import { getAmenityName } from "@methods";

export default {
  props: {
    code: {
      type: String,
      required: false,
      default: null,
    },
    translation: {
      type: String,
      required: false,
      default: null,
    },
    available: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  computed: {
    amenities() {
      return this.$store.getters["accommodation/amenities"];
    },
  },
  methods: {
    getAmenityName(amentiyCode) {
      return this.translation || getAmenityName(amentiyCode, this.$i18n.locale);
    },
    isAmenityAvailable(amenityCode) {
      if (this.available !== null) return this.available;
      return !!this.amenities.find((a) => a.amenity.code === amenityCode);
    },
  },
};
</script>
