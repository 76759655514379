<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <b-card>
      <template v-if="ownerManagedServices.length">
        <b-card-title>
          {{ $t("pages.accommodation.ownerManagedServices") }}
        </b-card-title>
        <b-row class="mb-4">
          <b-col
            v-for="(
              accommodationService, accommodationServiceIndex
            ) in ownerManagedServices"
            :key="`owner-service-${accommodationServiceIndex}`"
            cols="12"
            sm="3"
          >
            <accommodation-service-item
              :class="{
                'mb-3':
                  accommodationServiceIndex < ownerManagedServices.length - 1,
              }"
              :service="accommodationService"
            />
          </b-col>
        </b-row>
      </template>

      <b-card-title>
        {{ $t("pages.accommodation.agencyManagedServices") }}
      </b-card-title>
      <b-row>
        <b-col
          v-for="(
            accommodationService, accommodationServiceIndex
          ) in agencyManagedServices"
          :key="`owner-service-${accommodationServiceIndex}`"
          cols="12"
          sm="3"
        >
          <accommodation-service-item
            :class="{
              'mb-3':
                accommodationServiceIndex < agencyManagedServices.length - 1,
            }"
            :service="accommodationService"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCard, BOverlay, BRow, BCol, BCardTitle } from "bootstrap-vue";
import {
  getAccommodationServiceClientUnitPriceText,
  getServiceName,
} from "@methods";
import AccommodationServiceItem from "@/views/accommodations/accommodation/components/AccommodationServiceItem.vue";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BCardTitle,
    AccommodationServiceItem,
  },
  computed: {
    loading() {
      return this.$store.getters["accommodation/loadingServices"];
    },
    ownerManagedServices() {
      return this.$store.getters["accommodation/ownerManagedServices"];
    },
    agencyManagedServices() {
      return this.$store.getters["accommodation/agencyManagedServices"];
    },
  },
  methods: {
    getAccommodationServiceName(accommodationService) {
      if (!accommodationService?.service?.code) return this.$t("Service");
      return getServiceName(
        accommodationService.service.code,
        this.$i18n.locale
      );
    },
    getAccommodationServicePriceText(accommodationService) {
      if (!accommodationService?.price) return null;
      if (accommodationService.service?.code === "TOURIST_TAX")
        return this.$t("pages.accommodation.touristTaxPrice");
      return getAccommodationServiceClientUnitPriceText(
        accommodationService,
        this.$i18n.locale
      );
    },
  },
};
</script>
