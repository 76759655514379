<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <reviews-grid
      :rating="rating"
      :average-rating="averageRating"
      :max-rating="MAX_REVIEW_RATING"
      :reviews="reviews"
      hide-accommodation-name
    />
  </b-overlay>
</template>

<script>
import { BOverlay } from "bootstrap-vue";
import ReviewsGrid from "@/views/reviews/list/components/ReviewsGrid.vue";
import { MAX_REVIEW_RATING } from "@appConfig";

export default {
  components: {
    BOverlay,
    ReviewsGrid,
  },
  data() {
    return {
      MAX_REVIEW_RATING,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["accommodation/loadingReviews"];
    },
    reviews() {
      return this.$store.getters["accommodation/reviews"];
    },
    rating() {
      return this.$store.getters["accommodation/rating"];
    },
    averageRating() {
      return this.$store.getters["accommodation/averageRating"];
    },
  },
};
</script>
