<template>
  <div class="bathroom-card">
    <b-card-actions
      class="border font-weight-bold"
      action-collapse
      :collapsed="true"
    >
      <template #left-actions>
        {{ title }}
      </template>
      <b-row class="mb-2 mt-3">
        <b-col>
          <span>{{ bathroomSpecsText }}</span>
          <br />
          <small v-if="bathroom.ensuite">{{ ensuiteText }}</small>
          <small v-else>
            {{ $t("Independent") }}
          </small>
        </b-col>
      </b-row>
    </b-card-actions>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import BCardActions from "@/@foravila-core/components/b-card-actions/BCardActions.vue";

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
  },
  props: {
    bathroom: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      popupOpen: false,
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    bedrooms() {
      return this.$store.getters["accommodation/bedrooms"];
    },
    ensuiteText() {
      if (!this.bathroom.ensuite) return null;

      let bedroomIndex = null;

      if (this.bedrooms.length > 0) {
        this.bedrooms.forEach((bedroom, index) => {
          if (!bedroomIndex && bedroom.bathroom === this.bathroom["@id"]) {
            bedroomIndex = index + 1;
          }
        });
      }
      return bedroomIndex
        ? `${this.$t("pages.accommodation.bathroomEnsuiteTo")} ${bedroomIndex}`
        : null;
    },
    bathroomSpecsText() {
      const bathroomSpecs = [];
      if (this.bathroom.toilets > 0) {
        bathroomSpecs.push("WC");
      }
      if (this.bathroom.bathtubs > 0) {
        const translation = this.$t("Bathtub");
        bathroomSpecs.push(
          translation.charAt(0).toUpperCase() + translation.slice(1)
        );
      }
      if (this.bathroom.showers > 0) {
        const translation = this.$t("Shower");
        bathroomSpecs.push(
          translation.charAt(0).toUpperCase() + translation.slice(1)
        );
      }
      return bathroomSpecs.length > 0
        ? bathroomSpecs.join(", ")
        : this.$t("Not defined");
    },
  },
};
</script>

<style lang="scss">
.bathroom-card {
  .card {
    margin-bottom: 0;
    height: 100%;
    .card-header {
      background: #f6f6f6;
      border-bottom: #f6f6f6;
    }
  }
}
</style>
