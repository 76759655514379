<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <div id="amenities-card">
      <b-card :title="$t('Specifications')">
        <b-row class="mb-5">
          <b-col cols="12">
            <h5 class="mb-4 d-flex align-items-center">
              <feather-icon class="mr-2" icon="CropIcon" />
              {{ $t("Dimensions") }}
            </h5>
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col cols="12" sm="6" md="3" class="mb-3">
                <h6>
                  {{ $t("Lot") }}
                </h6>
                <b-card-text>
                  {{ plotSizeAmenityText }}
                </b-card-text>
              </b-col>
              <b-col cols="12" sm="6" md="3" class="mb-3">
                <h6>
                  {{ $t("Housing") }}
                </h6>
                <b-card-text>
                  {{ accommodationSizeAmenityText }}
                </b-card-text>
              </b-col>
              <b-col cols="12" sm="6" md="3" class="mb-3">
                <h6>
                  {{ $t("Garden") }}
                </h6>
                <b-card-text>
                  {{ gardenSizeAmenityText }}
                </b-card-text>
              </b-col>
              <b-col cols="12" sm="6" md="3" class="mb-3">
                <h6>
                  {{ $t("Terrace") }}
                </h6>
                <b-card-text>
                  {{ terraceSizeAmenityText }}
                </b-card-text>
              </b-col>
              <b-col cols="12" sm="6" md="3" class="mb-3">
                <h6>
                  {{ $t("Pool (surface)") }}
                </h6>
                <b-card-text>
                  {{ poolSizeText }}
                </b-card-text>
              </b-col>
              <b-col cols="12" sm="6" md="3" class="mb-3">
                <h6>
                  {{ $t("Pool (depth)") }}
                </h6>
                <b-card-text>
                  {{ poolDepthText }}
                </b-card-text>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <h5 class="mb-4 d-flex align-items-center">
              <feather-icon class="mr-2" icon="ListIcon" />
              {{ $t("Amenities") }}
            </h5>
          </b-col>
          <b-col cols="12">
            <vue-horizontal>
              <template v-slot:btn-prev>
                <feather-icon
                  size="32"
                  height="100"
                  class="btn-left"
                  icon="ChevronLeftIcon"
                />
              </template>
              <template v-slot:btn-next>
                <feather-icon
                  size="32"
                  height="100"
                  class="btn-right"
                  icon="ChevronRightIcon"
                />
              </template>
              <div :class="getTabClass(tabIndex, 0)" @click="tabIndex = 0">
                {{ $t("Pool") }}
              </div>
              <div :class="getTabClass(tabIndex, 1)" @click="tabIndex = 1">
                {{ $t("Air conditioning") }}
              </div>
              <div :class="getTabClass(tabIndex, 2)" @click="tabIndex = 2">
                {{ $t("Outdoors") }}
              </div>
              <div :class="getTabClass(tabIndex, 3)" @click="tabIndex = 3">
                {{ $t("Kitchen") }}
              </div>
              <div :class="getTabClass(tabIndex, 4)" @click="tabIndex = 4">
                {{ $t("Entertainment") }}
              </div>
              <div :class="getTabClass(tabIndex, 5)" @click="tabIndex = 5">
                {{ $t("General") }}
              </div>
              <div :class="getTabClass(tabIndex, 6)" @click="tabIndex = 6">
                {{ $t("pages.accommodation.vehicleAndParking") }}
              </div>
              <div :class="getTabClass(tabIndex, 7)" @click="tabIndex = 7">
                {{ $t("Security") }}
              </div>
            </vue-horizontal>
            <b-tabs
              v-model="tabIndex"
              class="amenities-nav"
              nav-wrapper-class="d-none"
              pills
            >
              <b-tab active>
                <b-row class="mt-4">
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="PRIVATE_SWIMMING_POOL" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="COMMUNAL_SWIMMING_POOL" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="PUBLIC_SWIMMING_POOL" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="HEATED_SWIMMING_POOL" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="COVERED_SWIMMING_POOL" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="JACUZZI" />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab>
                <b-row class="my-4">
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item
                      :translation="$t('pages.accommodation.acAllBedrooms')"
                      :available="allBedroomsACAmenity"
                    />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item
                      :translation="$t('pages.accommodation.acSomeBedrooms')"
                      :available="someBedroomsAcAmenity"
                    />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="AIR_CONDITIONING_LIVING_ROOM" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="AIR_CONDITIONING_KITCHEN" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="AIR_CONDITIONING_CORRIDOR" />
                  </b-col>
                </b-row>
                <b-row class="mb-4">
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item
                      :translation="
                        $t('pages.accommodation.heatingAllBedrooms')
                      "
                      :available="allBedroomsHeatingAmenity"
                    />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item
                      :translation="
                        $t('pages.accommodation.heatingSomeBedrooms')
                      "
                      :available="someBedroomsHeatingAmenity"
                    />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="HEATING_LIVING_ROOM" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="HEATING_KITCHEN" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="HEATING_CORRIDOR" />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="FAN" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="FIREPLACE" />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab>
                <b-row class="mt-4">
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="TERRACE" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="FURNISHED_TERRACE" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="BALCONY" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="GARDEN" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="BARBECUE" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="SOLARIUM" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="SUN_LOUNGERS" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="PLAYGROUND" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="ORCHARD" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="FARM_ANIMALS" />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab>
                <b-row class="mt-4">
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="KITCHEN" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="OVEN" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="MICROWAVE" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="FRIDGE" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="FREEZER" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="DISHES" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="DISHWASHER" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="COFFEE_MAKER" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="CAPSULE_COFFEE_MAKER" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="NESPRESSO" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="DOLCEGUSTO" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="TEAPOT" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="KETTLE" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="TOASTER" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="SQUEEZER" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="FOOD_MIXER" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="FRYING_PAN" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="SPICE_SELECTION" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="MINI_BAR" />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab>
                <b-row class="mt-4">
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="FREE_WIFI" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="PAID_WIFI" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="TV" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="SMART_TV" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="SATELLITE_TV" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="DVD_PLAYER" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="SOUND_SYSTEM" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="POOL_TABLE" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="TABLE_TENNIS" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="FUTBOLIN" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="BOARD_GAMES" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="GYM" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="SAUNA" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="HYDROMASSAGE_BATHTUB" />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab>
                <b-row class="mt-4">
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="BABY_COT" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="BABY_CHAIR" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="BED_LINEN" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="TOWELS" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="BATHROBE" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="SLIPPERS" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="WASHING_MACHINE" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="TUMBLE_DRYER" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="CLOTHES_HANGERS" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="CLOTHES_LINE" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="IRON" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="IRONING_BOARD" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="VACUUM" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="ELEVATOR" />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab>
                <b-row class="mt-4">
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="FREE_PARKING" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="PAID_PARKING" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="COVERED_PARKING" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="STREET_PARKING" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="CAR_NEEDED" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="CAR_RECOMMENDED" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="CAR_NOT_NEEDED" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="BICYCLE_STORAGE" />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab>
                <b-row class="mt-4">
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="FIRE_EXTINGUISHER" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="SMOKE_DETECTOR" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="FIRST_AID_KIT" />
                  </b-col>
                  <b-col cols="12" sm="6" md="3" class="mb-3">
                    <amenity-item code="SAFETY_BOX" />
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BCardText,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { getAmenityTextAndUnit } from "@methods";
import AmenityItem from "@/views/accommodations/accommodation/components/AmenityItem.vue";
import VueHorizontal from "vue-horizontal";

export default {
  components: {
    VueHorizontal,
    BOverlay,
    BCard,
    BRow,
    BCol,
    BCardText,
    BTabs,
    BTab,
    AmenityItem,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  methods: {
    getTabClass(selectedIndex, index) {
      return `mx-1 bg-light font-weight-bold rounded-pill cursor-pointer py-2 px-3 ${
        selectedIndex === index ? "btn-active" : ""
      }`;
    },
  },
  computed: {
    loading() {
      return this.loadingAmenities || this.loadingBedrooms;
    },
    loadingAmenities() {
      return this.$store.getters["accommodation/loadingAmenities"];
    },
    amenities() {
      return this.$store.getters["accommodation/amenities"];
    },
    loadingBedrooms() {
      return this.$store.getters["accommodation/loadingBedrooms"];
    },
    bedrooms() {
      return this.$store.getters["accommodation/bedrooms"];
    },
    plotSizeAmenityText() {
      const plotSizeAmenity =
        this.amenities.find((a) => a.amenity.code === "PLOT_SIZE") || null;
      return getAmenityTextAndUnit(plotSizeAmenity) || this.$t("Not defined");
    },
    accommodationSizeAmenityText() {
      const accommodationSizeAmenity =
        this.amenities.find((a) => a.amenity.code === "ACCOMMODATION_SIZE") ||
        null;
      return (
        getAmenityTextAndUnit(accommodationSizeAmenity) ||
        this.$t("Not defined")
      );
    },
    gardenSizeAmenityText() {
      const gardenSizeAmenity =
        this.amenities.find((a) => a.amenity.code === "GARDEN_SIZE") || null;
      return getAmenityTextAndUnit(gardenSizeAmenity) || this.$t("Not defined");
    },
    terraceSizeAmenityText() {
      const terraceSizeAmenity =
        this.amenities.find((a) => a.amenity.code === "TERRACE_SIZE") || null;
      return (
        getAmenityTextAndUnit(terraceSizeAmenity) || this.$t("Not defined")
      );
    },
    poolLengthAmenityText() {
      const poolLengthAmenity =
        this.amenities.find((a) => a.amenity.code === "POOL_LENGTH") || null;
      return getAmenityTextAndUnit(poolLengthAmenity);
    },
    poolWidthAmenityText() {
      const poolWidthAmenity =
        this.amenities.find((a) => a.amenity.code === "POOL_WIDTH") || null;
      return getAmenityTextAndUnit(poolWidthAmenity);
    },
    poolSizeText() {
      if (!this.poolLengthAmenityText || !this.poolWidthAmenityText)
        return this.$t("Not defined");
      return `${this.poolLengthAmenityText} x ${this.poolWidthAmenityText}`;
    },
    poolMinDepthAmenityText() {
      const poolMinDepthAmenity =
        this.amenities.find((a) => a.amenity.code === "POOL_MIN_DEPTH") || null;
      return getAmenityTextAndUnit(poolMinDepthAmenity);
    },
    poolMaxDepthAmenityText() {
      const poolMaxDepthAmenity =
        this.amenities.find((a) => a.amenity.code === "POOL_MAX_DEPTH") || null;
      return getAmenityTextAndUnit(poolMaxDepthAmenity);
    },
    poolDepthText() {
      if (!this.poolMinDepthAmenityText || !this.poolMaxDepthAmenityText)
        return this.$t("Not defined");
      return `${this.poolMinDepthAmenityText} x ${this.poolMaxDepthAmenityText}`;
    },
    allBedroomsACAmenity() {
      if (!this.bedrooms.length) return false;
      const bedroomsWithAc = this.bedrooms.filter((b) => b.ac);
      if (!bedroomsWithAc.length) return false;
      return this.bedrooms.length === bedroomsWithAc.length;
    },
    someBedroomsAcAmenity() {
      if (!this.bedrooms.length) return false;
      return !!this.bedrooms.find((b) => b.ac);
    },
    allBedroomsHeatingAmenity() {
      if (!this.bedrooms.length) return false;
      const bedroomsWithHeating = this.bedrooms.filter((b) => b.heating);
      if (!bedroomsWithHeating.length) return false;
      return this.bedrooms.length === bedroomsWithHeating.length;
    },
    someBedroomsHeatingAmenity() {
      if (!this.bedrooms.length) return false;
      return !!this.bedrooms.find((b) => b.heating);
    },
  },
};
</script>
<style lang="scss">
#amenities-card {
  .btn-left,
  .btn-right {
    padding: 6px;
    height: 100%;
  }
  .btn-left {
    background: linear-gradient(to left, #ffffff00 0, #fff 50%, #fff);
  }
  .btn-right {
    background: linear-gradient(to right, #ffffff00 0, #fff 50%, #fff);
  }
  .btn-active {
    background-color: #c9c9c9 !important;
    color: #6e6b7b !important;
    border: none !important;
  }
}
</style>
