<template>
  <b-card :title="$t('Location')">
    <!-- ADDRESS -->
    <b-row class="mb-4">
      <b-col cols="12">
        <h5 class="mb-4 d-flex align-items-center">
          <feather-icon class="mr-2" icon="MapIcon" />
          {{ $t("Address") }}
        </h5>
      </b-col>
      <b-col cols="12">
        <b-row>
          <b-col cols="12">
            <b-card-text>
              {{ fullAddress }}
            </b-card-text>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- GPS -->
    <b-row v-if="latitude && longitude">
      <b-col cols="12" md="auto">
        <b-button
          variant="primary"
          block
          @click="openGoogleMapsLink(latitude, longitude)"
        >
          {{ $t("pages.accommodation.showOnGoogleMaps") }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BCardText, BButton } from "bootstrap-vue";
import { getFullAddress } from "@methods";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    location() {
      return this.$store.getters["accommodation/location"];
    },
    fullAddress() {
      return (
        getFullAddress(this.location, this.$i18n.locale) ||
        this.$t("Not defined")
      );
    },
    latitude() {
      return this.location?.latitude || null;
    },
    longitude() {
      return this.location?.longitude || null;
    },
  },
  methods: {
    openGoogleMapsLink(latitude, longitude) {
      if (!latitude || !longitude) return;
      window.open(
        `https://www.google.com/maps/place/${latitude},${longitude}`,
        "_blank"
      );
    },
  },
};
</script>
