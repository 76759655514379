<template>
  <div class="bedroom-card">
    <b-card-actions
      class="border font-weight-bold"
      action-collapse
      :collapsed="true"
    >
      <template #left-actions>
        <div class="bg-light">{{ title }}</div>
      </template>

      <b-row class="mb-4">
        <b-col cols="12">
          <ul>
            <template v-if="beds.length">
              <li
                v-for="(bed, index) in beds"
                :key="`bed-${bedroom.id}-${index}`"
                class="py-3 px-4 border border-right-0 border-left-0 border-bottom-0"
                :class="{ 'border-top-0': index == 0 }"
              >
                <div class="font-medium">
                  {{ bedTypeName(bed) }}
                </div>
                <div>
                  <small v-if="bedDescriptionText(bed)">
                    {{ bedDescriptionText(bed) }}
                  </small>
                </div>
              </li>
            </template>

            <li
              class="d-flex justify-content-between py-3 px-4 border border-right-0 border-left-0 border-bottom-0"
            >
              <span class="card-text font-weight-bold">
                {{ $t("Type") }}
              </span>
              <small class="text-right">{{ bedroomTypeText }}</small>
            </li>

            <li
              class="d-flex justify-content-between py-3 px-4 border border-right-0 border-left-0 border-bottom-0"
            >
              <span class="card-text font-weight-bold">
                {{ $t("Floor") }}
              </span>
              <small class="text-right">{{ floorText }}</small>
            </li>

            <li
              class="d-flex justify-content-between py-3 px-4 border border-right-0 border-left-0 border-bottom-0"
            >
              <span class="card-text font-weight-bold">
                {{ $t("Size") }}
              </span>
              <small class="text-right">{{ sizeText }}</small>
            </li>

            <li
              class="d-flex justify-content-between py-3 px-4 border border-right-0 border-left-0 border-bottom-0"
            >
              <span class="card-text font-weight-bold">
                {{ $t("Views") }}
              </span>
              <small class="text-right">{{ viewsText }}</small>
            </li>

            <li
              class="d-flex justify-content-between py-3 px-4 border border-right-0 border-left-0 border-bottom-0"
            >
              <span class="card-text font-weight-bold">
                {{ $t("A/C") }}
              </span>
              <small class="text-right">{{ acText }}</small>
            </li>

            <li
              class="d-flex justify-content-between py-3 px-4 border border-right-0 border-left-0 border-bottom-0"
            >
              <span class="card-text font-weight-bold">
                {{ $t("Heating") }}
              </span>
              <small class="text-right">{{ heatingText }}</small>
            </li>

            <li
              class="d-flex justify-content-between py-3 px-4 border border-right-0 border-left-0 border-bottom-0"
            >
              <span class="card-text font-weight-bold">
                {{ $t("Bathroom ensuite") }}
              </span>
              <small class="text-right">{{ ensuiteBathroomText }}</small>
            </li>

            <li
              v-if="bedroomAmenitiesText"
              class="pt-3 px-4 border border-right-0 border-left-0 border-bottom-0"
            >
              <small class="text-right">{{ bedroomAmenitiesText }}</small>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-card-actions>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import {
  getBedTypeName,
  getBedDescriptionText,
  getBedroomTypeName,
  getBedroomAmenitiesText,
  getViewName,
  getHeatingName,
  getEnsuiteBathroomText,
  getFloorName,
} from "@methods";
import BCardActions from "@/@foravila-core/components/b-card-actions/BCardActions.vue";

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
  },
  props: {
    bedroom: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    bathrooms() {
      return this.$store.getters["accommodation/bathrooms"];
    },
    beds() {
      return Array.isArray(this.bedroom.beds) ? this.bedroom.beds : [];
    },
    bedroomTypeText() {
      return (
        getBedroomTypeName(this.bedroom.type, this.$i18n.locale) ||
        this.$t("Not defined")
      );
    },
    floorText() {
      return (
        getFloorName(this.bedroom.floor, this.$i18n.locale) ||
        this.$t("Not defined")
      );
    },
    sizeText() {
      return this.bedroom.size
        ? `${this.bedroom.size} m2`
        : this.$t("Not defined");
    },
    viewsText() {
      return (
        getViewName(this.bedroom.view, this.$i18n.locale) ||
        this.$t("Not defined")
      );
    },
    acText() {
      return this.bedroom.ac ? this.$t("Yes") : "No";
    },
    heatingText() {
      if (this.bedroom.heating) {
        return (
          getHeatingName(this.bedroom.heatingType, this.$i18n.locale) ||
          this.$t("Yes")
        );
      }
      return "No";
    },
    ensuiteBathroomText() {
      return (
        getEnsuiteBathroomText(
          this.bedroom,
          this.bathrooms,
          this.$i18n.locale
        ) || "No"
      );
    },
    bedroomAmenitiesText() {
      return getBedroomAmenitiesText(this.bedroom, this.$i18n.locale) || null;
    },
  },
  methods: {
    bedTypeName(bed) {
      return (
        getBedTypeName(bed.type, this.$i18n.locale) || this.$t("Not defined")
      );
    },
    bedDescriptionText(bed) {
      return getBedDescriptionText(bed, this.$i18n.locale);
    },
  },
};
</script>

<style lang="scss">
.bedroom-card {
  .card {
    margin-bottom: 0;
    height: 100%;
    .card-header {
      background: #f6f6f6;
      border-bottom: 1px solid #f6f6f6;
    }
  }
  ul {
    padding-left: 0 !important;
    list-style: none;
  }
}
</style>
