<template>
  <b-card :title="$t('Summary')">
    <b-row>
      <!-- PICUTRE -->
      <b-col cols="12" md="6" xl="3" class="mb-4 mb-md-0">
        <b-img :src="accommodationPicture" fluid-grow />
      </b-col>

      <!-- DETAILS -->
      <b-col cols="12" md="6" xl="9">
        <b-row>
          <!-- NAME -->
          <b-col
            v-if="accommodationName"
            cols="12"
            sm="4"
            md="6"
            xl="3"
            class="mb-3"
          >
            <h6 class="mb-1">
              {{ $t("Name") }}
            </h6>
            <b-card-text>
              {{ accommodationName }}
            </b-card-text>
          </b-col>
          <!-- TYPE -->
          <b-col
            v-if="accommodationType"
            cols="12"
            sm="4"
            md="6"
            xl="3"
            class="mb-3"
          >
            <h6 class="mb-1">
              {{ $t("Type") }}
            </h6>
            <b-card-text>
              {{ accommodationType }}
            </b-card-text>
          </b-col>
          <!-- CAPACITY -->
          <b-col
            v-if="accommodationCapacity"
            cols="12"
            sm="4"
            md="6"
            xl="3"
            class="mb-3"
          >
            <h6 class="mb-1">
              {{ $t("Capacity") }}
            </h6>
            <b-card-text>
              {{ accommodationCapacity }}
            </b-card-text>
          </b-col>
          <!-- BEDROOMS -->
          <b-col
            v-if="accommodationBedrooms"
            cols="12"
            sm="4"
            md="6"
            xl="3"
            class="mb-3"
          >
            <h6 class="mb-1">
              {{ $t("Bedrooms") }}
            </h6>
            <b-card-text>
              {{ accommodationBedrooms }}
            </b-card-text>
          </b-col>
          <!-- BATHROOMS -->
          <b-col
            v-if="accommodationBathrooms"
            cols="12"
            sm="4"
            md="6"
            xl="3"
            class="mb-3"
          >
            <h6 class="mb-1">
              {{ $t("Bathrooms") }}
            </h6>
            <b-card-text>
              {{ accommodationBathrooms }}
            </b-card-text>
          </b-col>
          <!-- TOURIST LICENCE -->
          <b-col cols="12" sm="4" md="6" xl="3" class="mb-3">
            <h6 class="mb-1">
              {{ $t("Tourist licence") }}
            </h6>
            <b-card-text v-if="accommodationTouristLicence">
              {{ accommodationTouristLicence }}
            </b-card-text>
            <b-badge v-else variant="danger">
              {{ $t("Pending") }}
            </b-badge>
          </b-col>
          <!-- POLICE REGISTRATION -->
          <b-col cols="12" sm="4" md="6" xl="3" class="mb-3">
            <h6 class="mb-1">
              {{ $t("Police registration") }}
            </h6>
            <b-card-text v-if="accommodationPoliceRegistration">
              {{ accommodationPoliceRegistration }}
            </b-card-text>
            <b-badge v-else variant="danger">
              {{ $t("Pending") }}
            </b-badge>
          </b-col>
          <!-- CADASTRAL REFERENCE -->
          <b-col cols="12" sm="4" md="6" xl="3" class="mb-3">
            <h6 class="mb-1">
              {{ $t("Cadastral reference") }}
            </h6>
            <b-card-text v-if="accommodationCadastralReference">
              {{ accommodationCadastralReference }}
            </b-card-text>
            <b-badge v-else variant="danger">
              {{ $t("Pending") }}
            </b-badge>
          </b-col>
          <!-- LOCKBOX CODE -->
          <b-col
            v-if="accommodationLockboxCode"
            cols="12"
            sm="4"
            md="6"
            xl="3"
            class="mb-0 mb-md-3"
          >
            <h6 class="mb-1">
              {{ $t("Lockbox code") }}
            </h6>
            <b-card-text>
              {{ accommodationLockboxCode }}
            </b-card-text>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BImg, BCardText, BBadge } from "bootstrap-vue";
import { getAccommodationTypeName } from "@methods";
import { ACCOMMODATION_PLACEHOLDER_SM } from "@consts";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BCardText,
    BBadge,
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    accommodationPicture() {
      return (
        this.accommodation?.coverPicture?.thumbnailUrl ||
        ACCOMMODATION_PLACEHOLDER_SM
      );
    },
    accommodationName() {
      return this.accommodation?.name || null;
    },
    accommodationType() {
      return getAccommodationTypeName(this.accommodation.type) || null;
    },
    accommodationCapacity() {
      return this.accommodation?.capacity || null;
    },
    accommodationBedrooms() {
      return this.accommodation?.bedroomsNumber || null;
    },
    accommodationBathrooms() {
      return this.accommodation?.bathroomsNumber || null;
    },
    accommodationTouristLicence() {
      if (!this.accommodation) return null;

      const text = [];
      if (this.accommodation.touristLicenceNumber)
        text.push(this.accommodation.touristLicenceNumber);
      if (this.accommodation.touristLicenceName)
        text.push(this.accommodation.touristLicenceName);

      return text.length ? text.join(" - ") : null;
    },
    accommodationPoliceRegistration() {
      if (!this.accommodation) return null;

      const text = [];
      if (this.accommodation.policeRegistrationNumber)
        text.push(this.accommodation.policeRegistrationNumber);
      if (this.accommodation.policeRegistrationName)
        text.push(this.accommodation.policeRegistrationName);

      return text.length ? text.join(" - ") : null;
    },
    accommodationCadastralReference() {
      return this.accommodation?.cadastralReference || null;
    },
    accommodationLockboxCode() {
      return this.accommodation?.details?.lockboxCode || null;
    },
  },
};
</script>
