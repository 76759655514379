<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <b-card :title="$t('Distribution')">
      <template v-if="bedrooms.length">
        <b-row class="mb-3">
          <b-col cols="12">
            <h5 class="mb-4 d-flex align-items-center">
              <feather-icon class="mr-2" icon="GridIcon" />
              {{ $t("Bedrooms") }}
            </h5>
          </b-col>
        </b-row>

        <b-row class="d-flex flex-wrap match-height">
          <b-col
            v-for="(bedroom, bedroomIndex) in bedrooms"
            :key="`bedroom-${bedroomIndex}`"
            cols="12"
            md="6"
            class="mb-3"
          >
            <bedroom-card
              :title="`${$t('Bedroom')} ${bedroomIndex + 1}`"
              :bedroom="bedroom"
              class="h-100"
            />
          </b-col>
        </b-row>
      </template>

      <template v-if="bathrooms.length">
        <b-row class="my-3">
          <b-col cols="12">
            <h5 class="mb-4 d-flex align-items-center">
              <feather-icon class="mr-2" icon="GridIcon" />
              {{ $t("Bathrooms") }}
            </h5>
          </b-col>
        </b-row>

        <b-row class="d-flex flex-wrap match-height">
          <b-col
            v-for="(bathroom, bathroomIndex) in bathrooms"
            :key="`bathroom-${bathroomIndex}`"
            cols="12"
            md="6"
            class="mb-3"
          >
            <bathroom-card
              :title="`${$t('Bathroom')} ${bathroomIndex + 1}`"
              :bathroom="bathroom"
              class="h-100"
            />
          </b-col>
        </b-row>
      </template>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BRow, BCol } from "bootstrap-vue";
import BedroomCard from "@/views/accommodations/accommodation/components/BedroomCard.vue";
import BathroomCard from "@/views/accommodations/accommodation/components/BathroomCard.vue";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BedroomCard,
    BathroomCard,
  },
  computed: {
    loading() {
      return this.$store.getters["accommodation/loadingBedroomsOrBathrooms"];
    },
    bedrooms() {
      return this.$store.getters["accommodation/bedrooms"];
    },
    bathrooms() {
      return this.$store.getters["accommodation/bathrooms"];
    },
  },
};
</script>
