<template>
  <div>
    <calendar
      :language="calendarLanguage"
      render-style="custom"
      :data-source="dataSource"
      :year="currentYear"
      :allow-overlap="false"
      :enable-range-selection="false"
      :display-week-number="false"
      :round-range-limits="false"
      :always-half-day="true"
      :custom-data-source-renderer="dataSourceRenderer"
      :custom-day-renderer="dayRenderer"
      @click-day="onDayClicked"
      @year-changed="onYearChanged"
    />

    <!-- Sidebar -->
    <b-sidebar
      id="booking-sidebar"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      sidebar-class="sidebar-lg"
      @hidden="onSidebarHidden"
    >
      <template #default="{ hide }">
        <booking-sidebar-content
          :hide-method="hide"
          :day="selectedDay"
          :events="selectedEvents"
        />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BSidebar, VBToggle } from "bootstrap-vue";
import BookingSidebarContent from "@/views/bookings/calendar/components/BookingSidebarContent.vue";
import Calendar from "v-year-calendar";
import "v-year-calendar/locales/v-year-calendar.ca";
import "v-year-calendar/locales/v-year-calendar.es";
import { formatCurrency } from "@formatters";

export default {
  components: {
    Calendar,
    BSidebar,
    BookingSidebarContent,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    bookings: {
      type: Array,
      default: () => [],
    },
    availabilities: {
      type: Array,
      default: () => [],
    },
    ownerRates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentYear: null,
      selectedDay: null,
      selectedEvents: [],
    };
  },
  computed: {
    calendarLanguage() {
      switch (this.$i18n.locale) {
        case "ca-ES":
          return "ca";
        case "es-ES":
          return "es";
        default:
          return "en";
      }
    },
    bookingsDataSource() {
      const filteredAccommodationBookings = this.bookings.filter(
        (booking) => booking.confirmed || booking.completed
      );
      if (!filteredAccommodationBookings.length) return [];

      return filteredAccommodationBookings.map((booking) => {
        const averageNightRate =
          booking.ownerPrice && booking.nights
            ? Math.floor(booking.ownerPrice / booking.nights)
            : null;

        return {
          type: "BOOKING",
          startDate: this.$moment(booking.checkin.split("T")[0], "YYYY-MM-DD")
            .startOf("day")
            .toDate(),
          endDate: this.$moment(booking.checkout.split("T")[0], "YYYY-MM-DD")
            .startOf("day")
            .toDate(),
          nightRate: averageNightRate ?? "-",
          ownerPrice: booking.ownerPrice,
          accommodation: this.accommodation?.name || null,
          adults: booking.adults || null,
          children: booking.children || null,
          babies: booking.babies || null,
          localizator: booking.localizator || null,
          client: booking.client?.fullName || null,
          accommodationArrivalTime:
            booking.details?.accommodationArrivalTime || null,
          accommodationDepartureTime:
            booking.details?.accommodationDepartureTime || null,
        };
      });
    },
    availabilitiesDataSource() {
      if (!this.availabilities.length) return [];
      return this.availabilities.map((availability) => ({
        type: "AVAILABILITY",
        startDate: this.$moment(availability.date.split("T")[0], "YYYY-MM-DD")
          .startOf("day")
          .toDate(),
        endDate: this.$moment(availability.date.split("T")[0], "YYYY-MM-DD")
          .startOf("day")
          .toDate(),
        available: availability.available || false,
        // ...
      }));
    },
    ownerRatesDataSource() {
      if (!this.ownerRates.length) return [];
      return this.ownerRates.map((ownerRate) => ({
        type: "RATE",
        startDate: this.$moment(ownerRate.date.split("T")[0], "YYYY-MM-DD")
          .startOf("day")
          .toDate(),
        endDate: this.$moment(ownerRate.date.split("T")[0], "YYYY-MM-DD")
          .startOf("day")
          .toDate(),
        price: ownerRate.price,
        // ...
      }));
    },
    dataSource() {
      return [
        ...this.ownerRatesDataSource,
        ...this.bookingsDataSource,
        ...this.availabilitiesDataSource,
      ];
    },
    uiCalendarYear() {
      return this.$store.getters["ui/calendarYear"];
    },
  },
  created() {
    this.initCurrentYear();
  },
  methods: {
    initCurrentYear() {
      this.currentYear = this.uiCalendarYear ?? new Date().getFullYear();
    },
    dataSourceRenderer(html, date, events) {
      // OWNER RATE
      const rateEvents = events
        .filter((e) => e.type === "RATE")
        .sort((a, b) => a.price - b.price);
      const rateEvent = rateEvents.length ? rateEvents[0] : null;

      // AVAILABILITY
      const availabilityEvents = events.filter(
        (e) => e.type === "AVAILABILITY"
      );
      const availabilityEvent = availabilityEvents.length
        ? availabilityEvents[0]
        : null;

      // BOOKINGS
      const bookingEvents = events
        .filter((e) => e.type === "BOOKING")
        .sort((a, b) => new Date(b.checkin) - new Date(a.checkin));
      const currentBooking = bookingEvents.length ? bookingEvents[0] : null;
      const currentDate = this.$moment(date).startOf("day");
      const currentDateIsPastDate = currentDate.isBefore(
        this.$moment().startOf("day")
      );

      // FIND OUT IF CURRENT DATE IS CHECKIN OR CHECKOUT DATE
      let checkin = null;
      let checkout = null;
      if (bookingEvents.length) {
        bookingEvents.forEach((bookingEvent) => {
          if (!!checkin && !!checkout) return;
          if (!checkin && currentDate.isSame(bookingEvent.startDate, "day")) {
            checkin = bookingEvent;
          }
          if (!checkout && currentDate.isSame(bookingEvent.endDate, "day")) {
            checkout = bookingEvent;
          }
        });
      }

      // DEFINE DAY PRICE TO DISPLAY
      let price = null;
      if ((checkin && checkout) || checkin) price = checkin.nightRate ?? null;
      else if (checkout && availabilityEvent?.available && rateEvent) {
        price = rateEvent.price ?? null;
      } else if (!checkin && !checkout && currentBooking) {
        price = currentBooking.nightRate ?? null;
      } else if (rateEvent && availabilityEvent?.available) {
        price = rateEvent.price ?? null;
      }

      // DEFINE DAY CLASSES
      const classes = [];
      if (!!checkin && !!checkout) classes.push("day-checkin-checkout");
      else if (checkin) {
        const previousDate = currentDate.subtract(1, "days");
        const previousDayAvailabilityEvent = this.dataSource.find(
          (event) =>
            previousDate.isSame(event.startDate, "day") &&
            event.type === "AVAILABILITY"
        );
        if (previousDayAvailabilityEvent?.available) {
          classes.push("day-checkin-available");
        } else classes.push("day-checkin-unavailable");
      } else if (checkout) {
        if (availabilityEvent?.available) {
          classes.push("day-checkout-available");
        } else classes.push("day-checkout-unavailable");
      } else if (currentBooking) classes.push("day-booking");
      else if (currentDateIsPastDate) classes.push("day-past");
      else if (availabilityEvent) {
        if (availabilityEvent.available) classes.push("day-available");
        else classes.push("day-unavailable");
      } else classes.push("day-empty");

      // eslint-disable-next-line no-param-reassign
      html.innerHTML = `
        <div class="${classes.join(" ")}" data-price="${
        formatCurrency(price, true) ?? ""
      }">
            ${date.getDate()}
        </div>`;
    },
    dayRenderer(html, date) {
      const currentDate = this.$moment(date).startOf("day");
      const today = this.$moment().startOf("day");
      if (!currentDate.isSame(today, "day")) return;
      // eslint-disable-next-line no-param-reassign
      html.parentElement.classList.add("current");
    },
    onDayClicked(eventData) {
      const bookingEvents = eventData.events.filter(
        (event) => event.type === "BOOKING"
      );
      if (!bookingEvents.length) return;

      this.selectedDay = eventData.date;
      this.selectedEvents = eventData.events;
      this.$root.$emit("bv::toggle::collapse", "booking-sidebar");
    },
    onSidebarHidden() {
      this.selectedDay = null;
      this.selectedEvents = [];
    },
    onYearChanged(eventData) {
      this.$store.dispatch("ui/setCalendarYear", eventData.currentYear);
    },
  },
};
</script>

<style lang="scss">
.calendar {
  padding: 0 !important;
  .calendar-header {
    margin-bottom: 50px;
  }
  .months-container {
    .month-container {
      height: 400px !important;
    }
  }
  .text-line-through {
    text-decoration: line-through;
  }
  table td {
    border: 1px solid lightgray;
    width: 38px !important;
    height: 50px !important;
  }
  table.month {
    th.month-title {
      font-weight: 500;
    }
    th.day-header {
      font-weight: 300;
      padding-bottom: 10px;
    }
  }
  .day.current {
    outline: 1px solid #787878 !important;
  }
  .day-content {
    background-color: #eee;
    padding: 0 !important;
    border-radius: 0 !important;
    height: 50px !important;
    width: 38px !important;
    font-weight: bold;
    font-size: 0.7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    [data-price]::after {
      content: attr(data-price);
      margin-top: 0.3rem;
      font-size: 0.7rem;
      font-weight: normal;
    }
    &:hover {
      background: #eee !important;
      cursor: inherit !important;
    }
  }
  .day-available,
  .day-unavailable,
  .day-booking,
  .day-checkin,
  .day-checkin-checkout,
  .day-checkin-available,
  .day-checkin-unavailable,
  .day-checkout-available,
  .day-checkout-unavailable,
  .day-past {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .day-available {
    background-color: #e9f8ee;
  }
  .day-unavailable {
    background-color: #eee;
  }
  .day-booking,
  .day-checkin-checkout,
  .day-checkin-available,
  .day-checkin-unavailable,
  .day-checkout-available,
  .day-checkout-unavailable {
    &:hover {
      cursor: pointer;
      outline: 1px solid #3e3e3e;
    }
  }
  .day-booking {
    background-color: #ffdf5f;
  }
  .day-checkin {
    background: linear-gradient(-53deg, #ffdf5f 49%, transparent 50%);
  }
  .day-checkin-checkout {
    background: linear-gradient(
      128deg,
      #ffdf5f 0%,
      #ffdf5f 48%,
      #fff 50%,
      #ffdf5f 52%,
      #ffdf5f 100%
    );
  }
  .day-checkin-available {
    background: linear-gradient(128deg, #e9f8ee 49%, #ffdf5f 50%);
  }
  .day-checkin-unavailable {
    background: linear-gradient(128deg, #eee 49%, #ffdf5f 50%);
  }
  .day-checkout-available {
    background: linear-gradient(128deg, #ffdf5f 49%, #e9f8ee 50%);
  }
  .day-checkout-unavailable {
    background: linear-gradient(128deg, #ffdf5f 49%, #eee 50%);
  }
  .day-past {
    background-color: #eee;
  }
}
</style>
