<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <div class="bookings-card">
      <b-row class="d-none d-md-block">
        <b-col>
          <b-card>
            <bookings-table
              :loading="loading"
              :bookings="bookings"
              :filters="filterOptions"
              hide-accommodation-name
              @booking-selected="onBookingSelected"
            />
          </b-card>
        </b-col>
      </b-row>

      <b-row class="d-md-none">
        <b-col>
          <mobile-bookings-list-card
            :loading="loading"
            :bookings="bookings"
            :filters="filterOptions"
            hide-accommodation-name
            @booking-selected="onBookingSelected"
          />
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay, BRow, BCol, BCard } from "bootstrap-vue";
import BookingsTable from "@/views/bookings/list/components/BookingsTable.vue";
import MobileBookingsListCard from "@/views/bookings/list/components/MobileBookingsListCard.vue";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BookingsTable,
    MobileBookingsListCard,
  },
  computed: {
    loading() {
      return this.$store.getters["accommodation/loadingBookings"];
    },
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    bookings() {
      return this.$store.getters["accommodation/bookings"];
    },
    filterOptions() {
      return [
        { value: "CONFIRMED", label: this.$t("Upcoming-f") },
        { value: "COMPLETED", label: this.$t("Completed-f") },
        { value: "CANCELLED", label: this.$t("Cancelled-f") },
        { value: "ALL", label: this.$t("All-f") },
      ];
    },
  },
  methods: {
    onBookingSelected(localizator) {
      this.$router.push({
        name: "foravila-booking-view",
        params: { localizator },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
.bookings-card {
  min-height: 200px;
}
</style>
