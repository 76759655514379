<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <b-card id="multimedia-card" :title="$t('Multimedia')">
      <template v-if="pictures.length">
        <b-row align-v="center" class="mb-4">
          <b-col>
            <h5><feather-icon icon="ImageIcon" /> {{ $t("Photos") }}</h5>
          </b-col>
          <b-col cols="auto" class="d-flex flex-row-reverse">
            <div
              slot="button-next"
              class="d-none d-md-block swiper-button-next swiper-button-black ml-2"
            />
            <div
              slot="button-prev"
              class="d-none d-md-block swiper-button-prev swiper-button-black"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <swiper :options="swiperOptions">
              <swiper-slide
                v-for="(picture, pictureIndex) in pictures"
                :key="`accommodation-image-${pictureIndex}`"
              >
                <b-img
                  :src="picture.thumbnailUrl"
                  fluid
                  class="mb-2 cursor-pointer"
                  no-body
                  @click="openLightboxPicture(pictureIndex)"
                />
              </swiper-slide>
            </swiper>
            <vue-chocolat
              ref="lightbox"
              :items="lightboxPictures"
              :options="{ loop: true }"
            />
          </b-col>
        </b-row>
      </template>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BImg } from "bootstrap-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import vueChocolat from "vue-chocolat";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BImg,
    Swiper,
    SwiperSlide,
    vueChocolat,
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        slidesPerView: "auto",
        centeredSlides: false,
        spaceBetween: 16,
        freeMode: false,
        grabCursor: true,
        loop: false,
        slidesOffsetAfter: 0,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 576px
          576: {
            slidesOffsetAfter: 15,
          },
        },
      },
    };
  },
  computed: {
    loading() {
      return this.$store.getters["accommodation/loadingPictures"];
    },
    pictures() {
      const pictures = this.$store.getters["accommodation/pictures"];
      if (!pictures.length) return [];
      return pictures.sort((a, b) => a.priority - b.priority);
    },
    lightboxPictures() {
      if (!this.pictures.length) return [];
      return this.pictures.map((picture) => ({
        src: picture.url,
      }));
    },
  },
  methods: {
    openLightboxPicture(index) {
      const { api } = this.$refs.lightbox;
      api.open(index);
    },
  },
};
</script>

<style lang="scss">
#multimedia-card {
  .swiper-slide {
    width: 220px !important;
    height: auto !important;
  }
  .swiper-button-prev,
  .swiper-container-rtl,
  .swiper-button-next {
    position: relative;
    top: 0;
    width: 32px !important;
    height: 32px !important;
    margin-top: 0;
    z-index: 0;
    cursor: pointer;
    background-size: 32px 32px !important;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj4KICA8ZyBpZD0iSWNvbi1BcnJvdy1SaWdodCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzIgMzIpIHJvdGF0ZSgxODApIj4KICAgIDxnIGlkPSJJY29uLUFycm93LVJpZ2h0LTIiIGRhdGEtbmFtZT0iSWNvbi1BcnJvdy1SaWdodCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzIgMzIpIHJvdGF0ZSgxODApIj4KICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV8zOTYiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDM5NiIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwKSIgZmlsbD0ibm9uZSIvPgogICAgICA8cGF0aCBpZD0iUGF0aF8yMzkiIGRhdGEtbmFtZT0iUGF0aCAyMzkiIGQ9Ik0xMC4zNDYsMGwtMS4xLDEuMSw3LjMsNy4zSDBWOS45NTVIMTYuNTQ5bC03LjMsNy4zLDEuMSwxLjEsOS4xNzctOS4xNzdaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2LjI0MiA2LjUwOCkiIGZpbGw9IiNmZmNkMDAiLz4KICAgIDwvZz4KICA8L2c+Cjwvc3ZnPgo=");
  }

  .swiper-button-next {
    right: 0;
    &:after {
      content: none;
    }
  }
  .swiper-button-prev {
    transform: rotate(180deg);
    left: 0;
    &:after {
      content: none;
    }
  }
  .swiper-pagination > .swiper-pagination-bullet-active {
    background-color: #ffcd00;
  }
}
.chocolat-wrapper {
  z-index: 1055 !important;
}
.chocolat-overlay.chocolat-visible {
  opacity: 1 !important;
}
.chocolat-wrapper .chocolat-right {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64'%3E%3Ccircle id='Ellipse_216' data-name='Ellipse 216' cx='32' cy='32' r='32' fill='%23fff'/%3E%3Cg id='Icon-Arrow-Right' transform='translate(56 56) rotate(180)'%3E%3Cg id='Icon-Arrow-Right-2' data-name='Icon-Arrow-Right' transform='translate(48 48) rotate(180)'%3E%3Crect id='Rectangle_396' data-name='Rectangle 396' width='48' height='48' transform='translate(0)' fill='none'/%3E%3Cpath id='Path_239' data-name='Path 239' d='M15.519,0,13.871,1.648,24.823,12.6H0v2.332H24.823L13.871,25.884l1.648,1.648L29.286,13.766Z' transform='translate(9.363 9.761)' fill='%23ffcd00'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}
.chocolat-wrapper .chocolat-left {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64'%3E%3Ccircle id='Ellipse_216' data-name='Ellipse 216' cx='32' cy='32' r='32' transform='translate(64 64) rotate(180)' fill='%23fff'/%3E%3Cg id='Icon-Arrow-Right' transform='translate(8 8)'%3E%3Cg id='Icon-Arrow-Right-2' data-name='Icon-Arrow-Right' transform='translate(48 48) rotate(180)'%3E%3Crect id='Rectangle_396' data-name='Rectangle 396' width='48' height='48' transform='translate(0)' fill='none'/%3E%3Cpath id='Path_239' data-name='Path 239' d='M15.519,0,13.871,1.648,24.823,12.6H0v2.332H24.823L13.871,25.884l1.648,1.648L29.286,13.766Z' transform='translate(9.363 9.761)' fill='%23ffcd00'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}
.chocolat-wrapper .chocolat-close {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64'%3E%3Ccircle id='Ellipse_216' data-name='Ellipse 216' cx='32' cy='32' r='32' transform='translate(64 64) rotate(180)' fill='%23fff'/%3E%3Cg id='Icon-Close' transform='translate(8 8)'%3E%3Crect id='Rectangle_457' data-name='Rectangle 457' width='48' height='48' fill='none'/%3E%3Cpath id='Path_237' data-name='Path 237' d='M42.681,18.76l-1.82-1.82L29.81,27.99,18.76,16.94l-1.82,1.82L27.99,29.81,16.94,40.861l1.82,1.82L29.81,31.63l11.05,11.051,1.82-1.82L31.63,29.81Z' transform='translate(-6.133 -5.779)' fill='%23ffcd00'/%3E%3C/g%3E%3C/svg%3E%0A");
}
.chocolat-wrapper .chocolat-bottom {
  display: none;
}
</style>
