<template>
  <div>
    <!-- Header -->
    <div class="bg-light sticky-top">
      <div class="d-flex justify-content-between align-items-center p-4">
        <div v-if="day">
          <h3 class="mb-0">
            {{ $t("Details") }}
          </h3>
          <small>
            {{ formattedDay }}
          </small>
        </div>
        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hideMethod"
          />
        </div>
      </div>
    </div>

    <!-- Body -->
    <div class="px-4 py-5">
      <template v-if="checkin || checkout">
        <div v-if="checkout" class="mb-6">
          <h4 class="text-center mb-3">
            {{ $t("Check out") }}
          </h4>
          <booking-sidebar-booking-item-compact :booking="checkout" />
        </div>
        <div v-if="checkin">
          <h4 class="text-center mb-3">
            {{ $t("Check-in") }}
          </h4>
          <booking-sidebar-booking-item-compact :booking="checkin" />
        </div>
      </template>
      <div v-else-if="currentBooking">
        <h4 class="text-center mb-3">
          {{ $t("Current booking") }}
        </h4>
        <booking-sidebar-booking-item :booking="currentBooking" />
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateObjectToDate } from "@formatters";
import BookingSidebarBookingItem from "@/views/bookings/calendar/components/BookingSidebarBookingItem.vue";
import BookingSidebarBookingItemCompact from "@/views/bookings/calendar/components/BookingSidebarBookingItemCompact.vue";

export default {
  components: {
    BookingSidebarBookingItem,
    BookingSidebarBookingItemCompact,
  },
  props: {
    hideMethod: {
      type: Function,
      required: true,
    },
    day: {
      type: Date,
      default: null,
    },
    events: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    formattedDay() {
      if (!this.day) return null;
      const formatting = { day: "numeric", month: "long", year: "numeric" };
      return formatDateObjectToDate(this.day, this.$i18n.locale, formatting);
    },
    bookings() {
      if (!this.events.length) return [];
      return this.events
        .filter((event) => event.type === "BOOKING")
        .sort((a, b) => new Date(b.checkin) - new Date(a.checkin));
    },
    checkin() {
      if (!this.bookings.length || !this.day) return null;
      return (
        this.bookings.find((bookingEvent) =>
          this.$moment(this.day).isSame(bookingEvent.startDate, "day")
        ) || null
      );
    },
    checkout() {
      if (!this.bookings.length || !this.day) return null;
      return (
        this.bookings.find((bookingEvent) =>
          this.$moment(this.day).isSame(bookingEvent.endDate, "day")
        ) || null
      );
    },
    currentBooking() {
      if (!this.bookings.length || !this.day) return null;
      return (
        this.bookings.find((bookingEvent) =>
          this.$moment(this.day).isBetween(
            bookingEvent.startDate,
            bookingEvent.endDate,
            "day",
            "[]"
          )
        ) || null
      );
    },
    // rate() {
    //   if (!this.events.length) return null
    //   const rateEvents = this.events
    //     .filter(e => e.type === 'RATE')
    //     .sort((a, b) => a.price - b.price)
    //   return rateEvents.length ? rateEvents[0] : null
    // },
    // availability() {
    //   if (!this.events.length) return null
    //   const availabilityEvents = this.events
    //     .filter(e => e.type === 'AVAILABILITY' && this.$moment(this.day).isBetween(e.startDate, e.endDate, 'day', '[]'))
    //   return availabilityEvents.length ? availabilityEvents[0] : null
    // },
    // isDayAvailable() {
    //   return this.availability?.available || false
    // },
  },
};
</script>
