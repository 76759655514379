<template>
  <div class="bg-light border p-4">
    <div class="text-grey font-weight-bold">
      {{ getAccommodationServiceName(service) }}
    </div>
    <small v-if="getAccommodationServicePriceText(service)">
      {{ getAccommodationServicePriceText(service) }}
    </small>
    <small v-else>
      <b-badge class="mt-2" variant="light-danger">
        {{ $t("Amount not defined") }}
      </b-badge>
    </small>
  </div>
</template>

<script>
import { BBadge } from "bootstrap-vue";
import {
  getAccommodationServiceClientUnitPriceText,
  getServiceName,
} from "@methods";

export default {
  components: {
    BBadge,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getAccommodationServiceName(accommodationService) {
      if (!accommodationService?.service?.code) return this.$t("Service");
      return getServiceName(
        accommodationService.service.code,
        this.$i18n.locale
      );
    },
    getAccommodationServicePriceText(accommodationService) {
      if (accommodationService.service?.code === "TOURIST_TAX")
        return this.$t("pages.accommodation.touristTaxPrice");
      if (!accommodationService?.price) return null;
      return getAccommodationServiceClientUnitPriceText(
        accommodationService,
        this.$i18n.locale
      );
    },
  },
};
</script>
